import { useEffect, useRef, useState } from "react";
import "./App.css";
import "rodal/lib/rodal.css";
import Swal from "sweetalert2";

interface Message {
	count: number;
	lastMessage: number | undefined;
}

const DefaultMessage: Message = {
	count: 0,
	lastMessage: undefined,
};

function ConvertLocalStorage(): Message {
	const value = localStorage.getItem("haram");
	return value ? JSON.parse(value) : DefaultMessage;
}
function App() {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [message, setMessage] = useState(ConvertLocalStorage());
	const [popUp, setPopUp] = useState(false);

	useEffect(() => {
		if (popUp) {
			Swal.fire({
				title: "Deine Daten wurden an Philipp weitergeleitet 😜",
				width: 600,
				padding: "3em",
				color: "#FF34B3",
				background: "#fff url('/computer-old-man.gif') center/cover",
				backdrop: `
				  rgba(0,0,123,0.4)
				  url('/heart-love.gif')
				  left top
				  no-repeat
				`,
				didClose() {
					setPopUp(false);
				},
			});
		}
	}, [popUp]);

	useEffect(() => {
		async function camera() {
			const camera = await navigator.mediaDevices.getUserMedia({
				video: {
					facingMode: {
						ideal: "user",
					},
				},
			});

			if (videoRef.current) {
				videoRef.current.srcObject = camera;

				// Spiegelt das Video horizontal
				videoRef.current.style.transform = "scaleX(-1)";
			}
		}

		camera();
	}, []);

	useEffect(() => {
		if (
			message.lastMessage &&
			Date.now() - message.lastMessage >= 1000 * 60 * 60
		) {
			setMessage(DefaultMessage);
			return;
		}

		localStorage.setItem("haram", JSON.stringify(message));
	}, [message]);

	const handleSubmit: React.DOMAttributes<HTMLFormElement>["onSubmit"] = (
		event
	) => {
		event.preventDefault();
		setPopUp(true);
		if (message.count >= 3) return;

		const target = event.target as any;
		const name = target.name.value;
		const number = target.number.value;
		fetch(
			"https://discord.com/api/webhooks/1210557322762391662/RPPCJg2xykW-W-IQapsEVf9UnWW5POHxC2Z1qhZIIVExmwtsxGVRqygh-hTrEADmw38a",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					content: null,
					embeds: [
						{
							color: 14964991,
							fields: [
								{
									name: "Number",
									value: number,
								},
								{
									name: "Name",
									value: name,
								},
							],
						},
					],
					attachments: [],
				}),
			}
		);

		const copyMessage = { ...message };

		copyMessage.count++;
		copyMessage.lastMessage = Date.now();

		setMessage(copyMessage);
	};
	return (
		<div id="main">
			<div id="text">Philipps Traumfrau:</div>

			<center>
				<form onSubmit={handleSubmit} id="actions">
					<div>
						<input
							type="text"
							placeholder="Your number/Insta/Snap"
							id="number"
						></input>
						<input
							type="text"
							placeholder="Your name"
							id="name"
						></input>
					</div>
					<div>
						<button type="submit">Mit Liebe absenden!💞</button>
						<p id="caption">!! picture/video will not be sent !!</p>
					</div>
				</form>
			</center>
			<video ref={videoRef} autoPlay={true} playsInline={true} />
		</div>
	);
}

export default App;
